exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-default-404-js": () => import("./../../../src/pages/default404.js" /* webpackChunkName: "component---src-pages-default-404-js" */),
  "component---src-pages-homepages-andovar-jsx": () => import("./../../../src/pages/homepages/andovar.jsx" /* webpackChunkName: "component---src-pages-homepages-andovar-jsx" */),
  "component---src-pages-innerpages-about-us-jsx": () => import("./../../../src/pages/innerpages/about-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-about-us-jsx" */),
  "component---src-pages-innerpages-applications-tools-jsx": () => import("./../../../src/pages/innerpages/applications-tools.jsx" /* webpackChunkName: "component---src-pages-innerpages-applications-tools-jsx" */),
  "component---src-pages-innerpages-arabic-translation-services-jsx": () => import("./../../../src/pages/innerpages/arabic-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-arabic-translation-services-jsx" */),
  "component---src-pages-innerpages-bangkok-flood-update-andovar-thailand-is-fully-operational-jsx": () => import("./../../../src/pages/innerpages/bangkok-flood-update-andovar-thailand-is-fully-operational.jsx" /* webpackChunkName: "component---src-pages-innerpages-bangkok-flood-update-andovar-thailand-is-fully-operational-jsx" */),
  "component---src-pages-innerpages-brazilian-portuguese-translation-services-jsx": () => import("./../../../src/pages/innerpages/brazilian-portuguese-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-brazilian-portuguese-translation-services-jsx" */),
  "component---src-pages-innerpages-bulgarian-translation-services-jsx": () => import("./../../../src/pages/innerpages/bulgarian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-bulgarian-translation-services-jsx" */),
  "component---src-pages-innerpages-burmese-translation-services-jsx": () => import("./../../../src/pages/innerpages/burmese-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-burmese-translation-services-jsx" */),
  "component---src-pages-innerpages-clients-jsx": () => import("./../../../src/pages/innerpages/clients.jsx" /* webpackChunkName: "component---src-pages-innerpages-clients-jsx" */),
  "component---src-pages-innerpages-contact-us-jsx": () => import("./../../../src/pages/innerpages/contact-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-contact-us-jsx" */),
  "component---src-pages-innerpages-continuous-localization-jsx": () => import("./../../../src/pages/innerpages/continuous-localization.jsx" /* webpackChunkName: "component---src-pages-innerpages-continuous-localization-jsx" */),
  "component---src-pages-innerpages-controlled-language-jsx": () => import("./../../../src/pages/innerpages/controlled-language.jsx" /* webpackChunkName: "component---src-pages-innerpages-controlled-language-jsx" */),
  "component---src-pages-innerpages-corpus-jsx": () => import("./../../../src/pages/innerpages/corpus.jsx" /* webpackChunkName: "component---src-pages-innerpages-corpus-jsx" */),
  "component---src-pages-innerpages-danish-translation-services-jsx": () => import("./../../../src/pages/innerpages/danish-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-danish-translation-services-jsx" */),
  "component---src-pages-innerpages-dutch-translation-services-jsx": () => import("./../../../src/pages/innerpages/dutch-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-dutch-translation-services-jsx" */),
  "component---src-pages-innerpages-enterprise-localization-jsx": () => import("./../../../src/pages/innerpages/enterprise-localization.jsx" /* webpackChunkName: "component---src-pages-innerpages-enterprise-localization-jsx" */),
  "component---src-pages-innerpages-enterprise-translation-jsx": () => import("./../../../src/pages/innerpages/enterprise-translation.jsx" /* webpackChunkName: "component---src-pages-innerpages-enterprise-translation-jsx" */),
  "component---src-pages-innerpages-estonian-translation-services-jsx": () => import("./../../../src/pages/innerpages/estonian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-estonian-translation-services-jsx" */),
  "component---src-pages-innerpages-finnish-translation-services-jsx": () => import("./../../../src/pages/innerpages/finnish-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-finnish-translation-services-jsx" */),
  "component---src-pages-innerpages-flemish-translation-services-jsx": () => import("./../../../src/pages/innerpages/flemish-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-flemish-translation-services-jsx" */),
  "component---src-pages-innerpages-french-canadian-translation-services-jsx": () => import("./../../../src/pages/innerpages/french-canadian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-french-canadian-translation-services-jsx" */),
  "component---src-pages-innerpages-french-translation-services-jsx": () => import("./../../../src/pages/innerpages/french-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-french-translation-services-jsx" */),
  "component---src-pages-innerpages-german-translation-services-jsx": () => import("./../../../src/pages/innerpages/german-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-german-translation-services-jsx" */),
  "component---src-pages-innerpages-greek-translation-services-jsx": () => import("./../../../src/pages/innerpages/greek-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-greek-translation-services-jsx" */),
  "component---src-pages-innerpages-hebrew-translation-services-jsx": () => import("./../../../src/pages/innerpages/hebrew-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-hebrew-translation-services-jsx" */),
  "component---src-pages-innerpages-hindi-translation-services-jsx": () => import("./../../../src/pages/innerpages/hindi-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-hindi-translation-services-jsx" */),
  "component---src-pages-innerpages-hungarian-translation-services-jsx": () => import("./../../../src/pages/innerpages/hungarian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-hungarian-translation-services-jsx" */),
  "component---src-pages-innerpages-indonesian-translation-services-jsx": () => import("./../../../src/pages/innerpages/indonesian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-indonesian-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-accessibility-content-jsx": () => import("./../../../src/pages/innerpages/industries/accessibility-content.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-accessibility-content-jsx" */),
  "component---src-pages-innerpages-industries-accessibility-solutions-jsx": () => import("./../../../src/pages/innerpages/industries/accessibility-solutions.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-accessibility-solutions-jsx" */),
  "component---src-pages-innerpages-industries-ai-voiceover-jsx": () => import("./../../../src/pages/innerpages/industries/ai-voiceover.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-ai-voiceover-jsx" */),
  "component---src-pages-innerpages-industries-audio-description-solutions-jsx": () => import("./../../../src/pages/innerpages/industries/audio-description-solutions.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-audio-description-solutions-jsx" */),
  "component---src-pages-innerpages-industries-automotive-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/automotive-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-automotive-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-broadcast-media-jsx": () => import("./../../../src/pages/innerpages/industries/broadcast-media.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-broadcast-media-jsx" */),
  "component---src-pages-innerpages-industries-cryptocurrency-fintech-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/cryptocurrency-fintech-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-cryptocurrency-fintech-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-e-commerce-localization-services-jsx": () => import("./../../../src/pages/innerpages/industries/e-commerce-localization-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-e-commerce-localization-services-jsx" */),
  "component---src-pages-innerpages-industries-energy-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/energy-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-energy-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-engineering-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/engineering-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-engineering-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-financial-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/financial-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-financial-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-food-beverages-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/food-beverages-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-food-beverages-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-inclusive-writing-solutions-jsx": () => import("./../../../src/pages/innerpages/industries/inclusive-writing-solutions.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-inclusive-writing-solutions-jsx" */),
  "component---src-pages-innerpages-industries-jsx": () => import("./../../../src/pages/innerpages/industries.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-jsx" */),
  "component---src-pages-innerpages-industries-localization-consultancy-solution-jsx": () => import("./../../../src/pages/innerpages/industries/localization-consultancy-solution.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-localization-consultancy-solution-jsx" */),
  "component---src-pages-innerpages-industries-manufacturing-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/manufacturing-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-manufacturing-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-market-research-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/market-research-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-market-research-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-marketing-localization-services-jsx": () => import("./../../../src/pages/innerpages/industries/marketing-localization-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-marketing-localization-services-jsx" */),
  "component---src-pages-innerpages-industries-medical-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/medical-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-medical-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-ngo-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/ngo-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-ngo-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-professional-legal-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/professional-legal-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-professional-legal-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-retail-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/retail-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-retail-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-sports-translation-services-jsx": () => import("./../../../src/pages/innerpages/industries/sports-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-sports-translation-services-jsx" */),
  "component---src-pages-innerpages-industries-travel-hospitality-jsx": () => import("./../../../src/pages/innerpages/industries/travel-hospitality.jsx" /* webpackChunkName: "component---src-pages-innerpages-industries-travel-hospitality-jsx" */),
  "component---src-pages-innerpages-integrations-and-connectors-jsx": () => import("./../../../src/pages/innerpages/integrations-and-connectors.jsx" /* webpackChunkName: "component---src-pages-innerpages-integrations-and-connectors-jsx" */),
  "component---src-pages-innerpages-italian-translation-services-jsx": () => import("./../../../src/pages/innerpages/italian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-italian-translation-services-jsx" */),
  "component---src-pages-innerpages-japanese-translation-services-jsx": () => import("./../../../src/pages/innerpages/japanese-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-japanese-translation-services-jsx" */),
  "component---src-pages-innerpages-khmer-translation-services-jsx": () => import("./../../../src/pages/innerpages/khmer-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-khmer-translation-services-jsx" */),
  "component---src-pages-innerpages-korean-translation-services-jsx": () => import("./../../../src/pages/innerpages/korean-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-korean-translation-services-jsx" */),
  "component---src-pages-innerpages-language-guides-jsx": () => import("./../../../src/pages/innerpages/language-guides.jsx" /* webpackChunkName: "component---src-pages-innerpages-language-guides-jsx" */),
  "component---src-pages-innerpages-latvian-translation-services-jsx": () => import("./../../../src/pages/innerpages/latvian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-latvian-translation-services-jsx" */),
  "component---src-pages-innerpages-leadership-jsx": () => import("./../../../src/pages/innerpages/leadership.jsx" /* webpackChunkName: "component---src-pages-innerpages-leadership-jsx" */),
  "component---src-pages-innerpages-lithuanian-translation-services-jsx": () => import("./../../../src/pages/innerpages/lithuanian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-lithuanian-translation-services-jsx" */),
  "component---src-pages-innerpages-localization-strategy-jsx": () => import("./../../../src/pages/innerpages/localization-strategy.jsx" /* webpackChunkName: "component---src-pages-innerpages-localization-strategy-jsx" */),
  "component---src-pages-innerpages-localization-technology-jsx": () => import("./../../../src/pages/innerpages/localization-technology.jsx" /* webpackChunkName: "component---src-pages-innerpages-localization-technology-jsx" */),
  "component---src-pages-innerpages-locations-jsx": () => import("./../../../src/pages/innerpages/locations.jsx" /* webpackChunkName: "component---src-pages-innerpages-locations-jsx" */),
  "component---src-pages-innerpages-maintaining-business-continuity-during-mass-street-protests-jsx": () => import("./../../../src/pages/innerpages/maintaining-business-continuity-during-mass-street-protests.jsx" /* webpackChunkName: "component---src-pages-innerpages-maintaining-business-continuity-during-mass-street-protests-jsx" */),
  "component---src-pages-innerpages-malay-translation-services-jsx": () => import("./../../../src/pages/innerpages/malay-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-malay-translation-services-jsx" */),
  "component---src-pages-innerpages-multilingual-desktop-publishing-services-jsx": () => import("./../../../src/pages/innerpages/multilingual-desktop-publishing-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-multilingual-desktop-publishing-services-jsx" */),
  "component---src-pages-innerpages-multilingual-seo-services-jsx": () => import("./../../../src/pages/innerpages/multilingual-seo-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-multilingual-seo-services-jsx" */),
  "component---src-pages-innerpages-norwegian-translation-services-jsx": () => import("./../../../src/pages/innerpages/norwegian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-norwegian-translation-services-jsx" */),
  "component---src-pages-innerpages-our-work-agoda-jsx": () => import("./../../../src/pages/innerpages/our-work/agoda.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-agoda-jsx" */),
  "component---src-pages-innerpages-our-work-agoda-old-jsx": () => import("./../../../src/pages/innerpages/our-work/agoda_old.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-agoda-old-jsx" */),
  "component---src-pages-innerpages-our-work-airbnb-jsx": () => import("./../../../src/pages/innerpages/our-work/airbnb.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-airbnb-jsx" */),
  "component---src-pages-innerpages-our-work-amway-jsx": () => import("./../../../src/pages/innerpages/our-work/amway.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-amway-jsx" */),
  "component---src-pages-innerpages-our-work-catalent-jsx": () => import("./../../../src/pages/innerpages/our-work/catalent.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-catalent-jsx" */),
  "component---src-pages-innerpages-our-work-ciena-jsx": () => import("./../../../src/pages/innerpages/our-work/ciena.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-ciena-jsx" */),
  "component---src-pages-innerpages-our-work-dksh-jsx": () => import("./../../../src/pages/innerpages/our-work/dksh.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-dksh-jsx" */),
  "component---src-pages-innerpages-our-work-drager-jsx": () => import("./../../../src/pages/innerpages/our-work/drager.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-drager-jsx" */),
  "component---src-pages-innerpages-our-work-firstcry-jsx": () => import("./../../../src/pages/innerpages/our-work/firstcry.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-firstcry-jsx" */),
  "component---src-pages-innerpages-our-work-haemonetics-jsx": () => import("./../../../src/pages/innerpages/our-work/haemonetics.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-haemonetics-jsx" */),
  "component---src-pages-innerpages-our-work-jsx": () => import("./../../../src/pages/innerpages/our-work.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-jsx" */),
  "component---src-pages-innerpages-our-work-lauder-jsx": () => import("./../../../src/pages/innerpages/our-work/lauder.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-lauder-jsx" */),
  "component---src-pages-innerpages-our-work-merck-jsx": () => import("./../../../src/pages/innerpages/our-work/merck.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-merck-jsx" */),
  "component---src-pages-innerpages-our-work-mnc-media-jsx": () => import("./../../../src/pages/innerpages/our-work/mnc-media.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-mnc-media-jsx" */),
  "component---src-pages-innerpages-our-work-nexercise-jsx": () => import("./../../../src/pages/innerpages/our-work/nexercise.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-nexercise-jsx" */),
  "component---src-pages-innerpages-our-work-nielsen-jsx": () => import("./../../../src/pages/innerpages/our-work/nielsen.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-nielsen-jsx" */),
  "component---src-pages-innerpages-our-work-nissan-jsx": () => import("./../../../src/pages/innerpages/our-work/nissan.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-nissan-jsx" */),
  "component---src-pages-innerpages-our-work-one-animation-jsx": () => import("./../../../src/pages/innerpages/our-work/one-animation.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-one-animation-jsx" */),
  "component---src-pages-innerpages-our-work-oracle-netsuite-jsx": () => import("./../../../src/pages/innerpages/our-work/oracle-netsuite.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-oracle-netsuite-jsx" */),
  "component---src-pages-innerpages-our-work-prometric-jsx": () => import("./../../../src/pages/innerpages/our-work/prometric.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-prometric-jsx" */),
  "component---src-pages-innerpages-our-work-prometric-old-jsx": () => import("./../../../src/pages/innerpages/our-work/prometric_old.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-prometric-old-jsx" */),
  "component---src-pages-innerpages-our-work-pronails-jsx": () => import("./../../../src/pages/innerpages/our-work/pronails.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-pronails-jsx" */),
  "component---src-pages-innerpages-our-work-pubg-jsx": () => import("./../../../src/pages/innerpages/our-work/pubg.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-pubg-jsx" */),
  "component---src-pages-innerpages-our-work-qigroup-jsx": () => import("./../../../src/pages/innerpages/our-work/qigroup.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-qigroup-jsx" */),
  "component---src-pages-innerpages-our-work-spotify-jsx": () => import("./../../../src/pages/innerpages/our-work/spotify.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-spotify-jsx" */),
  "component---src-pages-innerpages-our-work-spotify-old-jsx": () => import("./../../../src/pages/innerpages/our-work/spotify_old.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-spotify-old-jsx" */),
  "component---src-pages-innerpages-our-work-sworkit-jsx": () => import("./../../../src/pages/innerpages/our-work/sworkit.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-sworkit-jsx" */),
  "component---src-pages-innerpages-our-work-travelocity-jsx": () => import("./../../../src/pages/innerpages/our-work/travelocity.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-travelocity-jsx" */),
  "component---src-pages-innerpages-our-work-uber-jsx": () => import("./../../../src/pages/innerpages/our-work/uber.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-uber-jsx" */),
  "component---src-pages-innerpages-our-work-warner-jsx": () => import("./../../../src/pages/innerpages/our-work/warner.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-work-warner-jsx" */),
  "component---src-pages-innerpages-polish-translation-services-jsx": () => import("./../../../src/pages/innerpages/polish-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-polish-translation-services-jsx" */),
  "component---src-pages-innerpages-portuguese-translation-services-jsx": () => import("./../../../src/pages/innerpages/portuguese-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-portuguese-translation-services-jsx" */),
  "component---src-pages-innerpages-privacy-policy-jsx": () => import("./../../../src/pages/innerpages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-innerpages-privacy-policy-jsx" */),
  "component---src-pages-innerpages-professional-translation-services-jsx": () => import("./../../../src/pages/innerpages/professional-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-professional-translation-services-jsx" */),
  "component---src-pages-innerpages-request-quote-jsx": () => import("./../../../src/pages/innerpages/request-quote.jsx" /* webpackChunkName: "component---src-pages-innerpages-request-quote-jsx" */),
  "component---src-pages-innerpages-resource-center-andovar-academy-jsx": () => import("./../../../src/pages/innerpages/resource-center/andovar-academy.jsx" /* webpackChunkName: "component---src-pages-innerpages-resource-center-andovar-academy-jsx" */),
  "component---src-pages-innerpages-romanian-translation-services-jsx": () => import("./../../../src/pages/innerpages/romanian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-romanian-translation-services-jsx" */),
  "component---src-pages-innerpages-russian-translation-services-jsx": () => import("./../../../src/pages/innerpages/russian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-russian-translation-services-jsx" */),
  "component---src-pages-innerpages-serbian-translation-services-jsx": () => import("./../../../src/pages/innerpages/serbian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-serbian-translation-services-jsx" */),
  "component---src-pages-innerpages-simplified-chinese-translation-services-jsx": () => import("./../../../src/pages/innerpages/simplified-chinese-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-simplified-chinese-translation-services-jsx" */),
  "component---src-pages-innerpages-slovak-translation-services-jsx": () => import("./../../../src/pages/innerpages/slovak-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-slovak-translation-services-jsx" */),
  "component---src-pages-innerpages-solutions-ai-data-jsx": () => import("./../../../src/pages/innerpages/solutions/ai-data.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-ai-data-jsx" */),
  "component---src-pages-innerpages-solutions-app-translation-jsx": () => import("./../../../src/pages/innerpages/solutions/app-translation.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-app-translation-jsx" */),
  "component---src-pages-innerpages-solutions-artificial-intelligence-jsx": () => import("./../../../src/pages/innerpages/solutions/artificial-intelligence.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-artificial-intelligence-jsx" */),
  "component---src-pages-innerpages-solutions-automated-subtitling-jsx": () => import("./../../../src/pages/innerpages/solutions/automated-subtitling.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-automated-subtitling-jsx" */),
  "component---src-pages-innerpages-solutions-bilingual-corpora-services-jsx": () => import("./../../../src/pages/innerpages/solutions/bilingual-corpora-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-bilingual-corpora-services-jsx" */),
  "component---src-pages-innerpages-solutions-consulting-services-jsx": () => import("./../../../src/pages/innerpages/solutions/consulting-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-consulting-services-jsx" */),
  "component---src-pages-innerpages-solutions-custom-text-data-services-jsx": () => import("./../../../src/pages/innerpages/solutions/custom-text-data-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-custom-text-data-services-jsx" */),
  "component---src-pages-innerpages-solutions-data-collection-jsx": () => import("./../../../src/pages/innerpages/solutions/data-collection.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-data-collection-jsx" */),
  "component---src-pages-innerpages-solutions-digital-content-localization-jsx": () => import("./../../../src/pages/innerpages/solutions/digital-content-localization.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-digital-content-localization-jsx" */),
  "component---src-pages-innerpages-solutions-document-translation-jsx": () => import("./../../../src/pages/innerpages/solutions/document-translation.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-document-translation-jsx" */),
  "component---src-pages-innerpages-solutions-elearning-translation-services-jsx": () => import("./../../../src/pages/innerpages/solutions/elearning-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-elearning-translation-services-jsx" */),
  "component---src-pages-innerpages-solutions-game-localization-jsx": () => import("./../../../src/pages/innerpages/solutions/game-localization.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-game-localization-jsx" */),
  "component---src-pages-innerpages-solutions-jsx": () => import("./../../../src/pages/innerpages/solutions.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-jsx" */),
  "component---src-pages-innerpages-solutions-large-language-models-jsx": () => import("./../../../src/pages/innerpages/solutions/large-language-models.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-large-language-models-jsx" */),
  "component---src-pages-innerpages-solutions-machine-translation-jsx": () => import("./../../../src/pages/innerpages/solutions/machine-translation.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-machine-translation-jsx" */),
  "component---src-pages-innerpages-solutions-media-localization-jsx": () => import("./../../../src/pages/innerpages/solutions/media-localization.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-media-localization-jsx" */),
  "component---src-pages-innerpages-solutions-monolingual-corpora-services-jsx": () => import("./../../../src/pages/innerpages/solutions/monolingual-corpora-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-monolingual-corpora-services-jsx" */),
  "component---src-pages-innerpages-solutions-mt-post-editing-jsx": () => import("./../../../src/pages/innerpages/solutions/mt-post-editing.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-mt-post-editing-jsx" */),
  "component---src-pages-innerpages-solutions-multicultural-video-data-collection-services-jsx": () => import("./../../../src/pages/innerpages/solutions/multicultural-video-data-collection-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-multicultural-video-data-collection-services-jsx" */),
  "component---src-pages-innerpages-solutions-multilingual-content-solutions-jsx": () => import("./../../../src/pages/innerpages/solutions/multilingual-content-solutions.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-multilingual-content-solutions-jsx" */),
  "component---src-pages-innerpages-solutions-multilingual-data-annotation-services-jsx": () => import("./../../../src/pages/innerpages/solutions/multilingual-data-annotation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-multilingual-data-annotation-services-jsx" */),
  "component---src-pages-innerpages-solutions-multilingual-voice-data-collection-services-jsx": () => import("./../../../src/pages/innerpages/solutions/multilingual-voice-data-collection-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-multilingual-voice-data-collection-services-jsx" */),
  "component---src-pages-innerpages-solutions-professional-interpretation-services-jsx": () => import("./../../../src/pages/innerpages/solutions/professional-interpretation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-professional-interpretation-services-jsx" */),
  "component---src-pages-innerpages-solutions-software-localization-translation-services-jsx": () => import("./../../../src/pages/innerpages/solutions/software-localization-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-software-localization-translation-services-jsx" */),
  "component---src-pages-innerpages-solutions-subtitle-translation-jsx": () => import("./../../../src/pages/innerpages/solutions/subtitle-translation.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-subtitle-translation-jsx" */),
  "component---src-pages-innerpages-solutions-translation-language-services-jsx": () => import("./../../../src/pages/innerpages/solutions/translation-language-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-translation-language-services-jsx" */),
  "component---src-pages-innerpages-solutions-translation-localization-services-jsx": () => import("./../../../src/pages/innerpages/solutions/translation-localization-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-translation-localization-services-jsx" */),
  "component---src-pages-innerpages-solutions-video-localization-translation-services-jsx": () => import("./../../../src/pages/innerpages/solutions/video-localization-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-video-localization-translation-services-jsx" */),
  "component---src-pages-innerpages-solutions-voiceover-services-jsx": () => import("./../../../src/pages/innerpages/solutions/voiceover-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-voiceover-services-jsx" */),
  "component---src-pages-innerpages-solutions-website-localization-services-jsx": () => import("./../../../src/pages/innerpages/solutions/website-localization-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-solutions-website-localization-services-jsx" */),
  "component---src-pages-innerpages-spanish-latin-american-translation-services-jsx": () => import("./../../../src/pages/innerpages/spanish-latin-american-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-spanish-latin-american-translation-services-jsx" */),
  "component---src-pages-innerpages-spanish-translation-services-jsx": () => import("./../../../src/pages/innerpages/spanish-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-spanish-translation-services-jsx" */),
  "component---src-pages-innerpages-swedish-translation-services-jsx": () => import("./../../../src/pages/innerpages/swedish-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-swedish-translation-services-jsx" */),
  "component---src-pages-innerpages-tagalog-translation-services-jsx": () => import("./../../../src/pages/innerpages/tagalog-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-tagalog-translation-services-jsx" */),
  "component---src-pages-innerpages-technical-translation-services-jsx": () => import("./../../../src/pages/innerpages/technical-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-technical-translation-services-jsx" */),
  "component---src-pages-innerpages-term-base-exchange-tbx-jsx": () => import("./../../../src/pages/innerpages/term-base-exchange-tbx.jsx" /* webpackChunkName: "component---src-pages-innerpages-term-base-exchange-tbx-jsx" */),
  "component---src-pages-innerpages-terminology-management-jsx": () => import("./../../../src/pages/innerpages/terminology-management.jsx" /* webpackChunkName: "component---src-pages-innerpages-terminology-management-jsx" */),
  "component---src-pages-innerpages-thai-translation-services-jsx": () => import("./../../../src/pages/innerpages/thai-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-thai-translation-services-jsx" */),
  "component---src-pages-innerpages-thank-you-jsx": () => import("./../../../src/pages/innerpages/thank-you.jsx" /* webpackChunkName: "component---src-pages-innerpages-thank-you-jsx" */),
  "component---src-pages-innerpages-traditional-chinese-translation-services-jsx": () => import("./../../../src/pages/innerpages/traditional-chinese-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-traditional-chinese-translation-services-jsx" */),
  "component---src-pages-innerpages-transcreation-adaptation-jsx": () => import("./../../../src/pages/innerpages/transcreation-adaptation.jsx" /* webpackChunkName: "component---src-pages-innerpages-transcreation-adaptation-jsx" */),
  "component---src-pages-innerpages-translation-management-jsx": () => import("./../../../src/pages/innerpages/translation-management.jsx" /* webpackChunkName: "component---src-pages-innerpages-translation-management-jsx" */),
  "component---src-pages-innerpages-translation-memory-exchange-tmx-jsx": () => import("./../../../src/pages/innerpages/translation-memory-exchange-tmx.jsx" /* webpackChunkName: "component---src-pages-innerpages-translation-memory-exchange-tmx-jsx" */),
  "component---src-pages-innerpages-translation-quality-management-jsx": () => import("./../../../src/pages/innerpages/translation-quality-management.jsx" /* webpackChunkName: "component---src-pages-innerpages-translation-quality-management-jsx" */),
  "component---src-pages-innerpages-translation-termbase-jsx": () => import("./../../../src/pages/innerpages/translation-termbase.jsx" /* webpackChunkName: "component---src-pages-innerpages-translation-termbase-jsx" */),
  "component---src-pages-innerpages-translators-style-guide-jsx": () => import("./../../../src/pages/innerpages/translators-style-guide.jsx" /* webpackChunkName: "component---src-pages-innerpages-translators-style-guide-jsx" */),
  "component---src-pages-innerpages-turkish-translation-services-jsx": () => import("./../../../src/pages/innerpages/turkish-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-turkish-translation-services-jsx" */),
  "component---src-pages-innerpages-ukrainian-translation-services-jsx": () => import("./../../../src/pages/innerpages/ukrainian-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-ukrainian-translation-services-jsx" */),
  "component---src-pages-innerpages-vietnamese-translation-services-jsx": () => import("./../../../src/pages/innerpages/vietnamese-translation-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-vietnamese-translation-services-jsx" */),
  "component---src-pages-innerpages-whyandovar-jsx": () => import("./../../../src/pages/innerpages/whyandovar.jsx" /* webpackChunkName: "component---src-pages-innerpages-whyandovar-jsx" */),
  "component---src-pages-innerpages-xliff-jsx": () => import("./../../../src/pages/innerpages/xliff.jsx" /* webpackChunkName: "component---src-pages-innerpages-xliff-jsx" */)
}

